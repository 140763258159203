<template>
  <div class="terms" v-if="page">
    <div class="outer-container">
      <div class="inner-container">
        <div class="terms-content" v-html="page.elements.terms.value"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Kontent from "@/kontent";
export default {
  name: 'Terms',
  metaInfo () {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  props: {},
  data () {
    return {
      page: null
    }
  },
  mounted () {
    Analytics.trackPage('Terms')
    Kontent.getItemByType('terms_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style lang="scss">
.terms-content {
  margin-top: 30px;
  margin-bottom: 50px;
  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: bold;
  }
  ul {
    margin: 20px 0;
    padding-left: 15px;

    > li {
      list-style-type: disc;
    }
  }
  ol {
    margin: 20px 0;
    padding-left: 15px;

    > li {
      list-style-type: decimal;
    }
  }
}
</style>
